import * as React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../layouts/Layout'
import { legalPage } from './legal.module.scss'
import { section, wrapper, paragraph, termsOfUseLink } from './terms-of-use.module.scss'

const Legal = () => {
  return (
    <Layout className={legalPage} footerVisible={false}>
      <Helmet>
        <title>Legal - Emso</title>
      </Helmet>
      <section className={section}>
        <div className={wrapper}>
          <div className="small-12 medium-12 large-10 xlarge-8">
            <h1>Copyright</h1>
            <div className={paragraph}>
              &copy; {new Date().getFullYear()} Emso Asset Management Limited. All Rights Reserved. Use of this Site is governed by the{' '}
              <u>
                <a className={termsOfUseLink} href="/terms-of-use">
                  Online Access and Terms of Use Agreement
                </a>
              </u>
              . The content of this Site is provided to users "as is" without any express or implied warranty. Emso Asset Management Limited
              is a limited company incorporated in England with company number 04916145. Our registered address is 110 Park Street,
              London, W1K 6NX, United Kingdom.
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Legal
